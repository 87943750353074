import React from 'react';
import Images from '../../constants/Images';

import './BottomBar.css';

export default function BottomBar() {
  return (
    <div className="bottomBar-container">
      <div className="bottomBar-textFont">&copy; Avi Khemani 2020</div>
      <div className="bottomBar-subContainer">
        <a className="bottomBar-buttonFont" href="mailto:avi@habitatapp.co?subject=Support Email for Stress Ball">support</a>
        <a className="bottomBar-buttonFont" href="terms.pdf">terms</a>
        <a className="bottomBar-buttonFont" href="privacypolicy.pdf">privacy</a>
      </div>
    </div>
  )
}
