import React, { useState, useEffect } from 'react';

import './BreathePage.css';

export default function BreathePage() {
  const [size, setSize] = useState(40);
  const [timerID, setTimerID] = useState(null);
  const [breathingOut, setBreathingOut] = useState(false);

  useEffect(() => {
    if (size > 320) {
      clearTimeout(timerID);
      setBreathingOut(true);
    } else if (size < 40) {
      clearTimeout(timerID);
      setBreathingOut(false);
    }
  }, [size])

  const breathePress = (e) => {
    if (breathingOut) { return; }
    const id = setInterval(() => {
      setSize((currSize) => currSize+1)
    }, 10);
    setTimerID(id);
  };

  const breatheRelease = (e) => {
    if (!breathingOut) {
      clearTimeout(timerID);
    }
    if (size < 320) { return; }
    const id = setInterval(() => {
      setSize((currSize) => currSize-1)
    }, 10);
    setTimerID(id);
    setBreathingOut(true);
  };

  return (
    <div className="breathePage-container" onMouseDown={breathePress} onMouseUp={breatheRelease}>
      <div className="breathePage-breatheText" style={{marginBottom: 220-size/2}}>
        {breathingOut ? 'Release and breathe out.' : 'Press down and breathe in.'}
      </div>
      <div className="breathePage-breatheView" style={{width: size, height: size, borderRadius: size/2}}/>
    </div>
  )


}
