import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';

import './ChillTokPage.css';

export default function ChillTokPage() {
  const { videoUrl } = useParams();

  return (
    <div className="chilltokPage-container">
      <ReactPlayer
        playsinline={true}
        className="chilltokPage-videoplayer"
        url={`https://d375r3zawzejml.cloudfront.net/${videoUrl}.mp4`}
        playing={true}
        loop={true}
        volume={0}
        width="100vh"
        height="100vh"
      />
    </div>
  )


}
