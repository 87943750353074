import React from 'react';

import './DownloadFooter.css';

export default function DownloadFooter({text}) {
  return (
    <div>
      <div style={{ height: '80px' }} />
      <div className="downloadFooter-downloadAppContainer">
        <div className="downloadFooter-downloadAppText">{text}</div>
        <a
          className="downloadFooter-textButton"
          href="https://apps.apple.com/us/app/stress-ball-anxiety-relief/id1546704945"
        >
          Download the app.
        </a>
      </div>
    </div>
  );
}
