import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

import logo from './logo.svg';
import audio from "./assets/audio/sparkle.mp3";
import './App.css';

import TopBar from './components/topBar/TopBar';
import BottomBar from './components/bottomBar/BottomBar';
import LandingPage from './components/landingPage/LandingPage';
import BreathePage from './components/breathePage/BreathePage';
import ChillTokPage from './components/chilltokPage/ChillTokPage';
import DownloadFooter from './components/downloadFooter/DownloadFooter';

function App() {
  const landingPage = (
    <div>
      <TopBar />
      <LandingPage />
      <BottomBar />
    </div>
  );

  const breathePage = (
    <div>
      <TopBar />
      <BreathePage />
      <DownloadFooter text="Breathe your anxiety away." />
    </div>
  )

  const chilltokPage = (
    <div>
      <TopBar />
      <ChillTokPage />
      <DownloadFooter text="Scroll your anxiety away."/>
    </div>
  )

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={landingPage} />
          <Route path="/breathe" element={breathePage} />
          <Route path="/chilltok" element={<Navigate to="/chilltok/nature01" />} />
          <Route path="/chilltok/:videoUrl" element={chilltokPage} />
        </Routes>
      </BrowserRouter>
      <ReactAudioPlayer
        src={audio}
        autoPlay={true}
        loop={true}
      />
    </div>
  );
}

export default App;
