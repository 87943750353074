import React from 'react';
import Images from '../../constants/Images';

import './TopBar.css';

export default function TopBar() {
  return (
    <div className="topBar-container">
      <a className="topBar-titleContainer" href="/">
        <img className="topBar-appIcon" src={Images.appIcon}/>
        <div className="topBar-titleFont">Stress Ball</div>
      </a>
      <div className="topBar-tabsContainer">
        <a className="topBar-buttonFont" href="/breathe">Breathe</a>
        <a className="topBar-buttonFont">Fidget</a>
        <a className="topBar-buttonFont" href="/chilltok">ChillTok</a>
      </div>
    </div>
  )
}
