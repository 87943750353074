import React from 'react';
import Images from '../../constants/Images';

import './LandingPage.css';

export default function LandingPage() {

  function downloadAppPress() {

  }

  return (
    <div className="landingPage-container">
      <div className="landingPage-subContainer">
        <div className="landingPage-mainText">Relieve Your Anxiety</div>
        <div className="landingPage-subText">Calm down with interactive, visual &amp; haptic experiences</div>
        <a className="landingPage-downloadButton" href="https://apps.apple.com/us/app/stress-ball-anxiety-relief/id1546704945">
          <img src={Images.downloadButton} className="landingPage-downloadImage" />
        </a>
      </div>
      <img src={Images.appScreenshots} className="landingPage-appScreenshotsImage" />
    </div>

  )


}
